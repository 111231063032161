import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function Blackboxdetails() {
  const location = useLocation();
  const filterData = location.state?.filterData;
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Show 10 items per page

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const localUserData = JSON.parse(localStorage.getItem("user"));
    if (!localUserData?._id) {
      alert("User not logged in.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.proleverageadmin.in/api/amazon/getitems2",
        {
          params: {
            query:
              filterData?.selectedCategories?.map((c) => c.label).join(",") ||
              "",
            userId: localUserData._id,
            country: filterData.country,
            min_price: filterData.price.min,
            max_price: filterData.price.max,
            category_id:
              filterData?.selectedCategories?.map((c) => c.value).join(",") ||
              "",
          },
        }
      );
      const products = response.data.data.products || [];
      setData(products);
    } catch (error) {
      console.error("Error fetching products:", error);
      setError("Failed to load products.");
    } finally {
      setLoading(false);
    }
  };

  const convertSalesVolume = (volume) => {
    if (!volume) return 0;
    const match = volume.match(/^([\d.]+)([a-zA-Z]*)/);
    if (!match) return 0;
    const value = parseFloat(match[1]);
    const units = { K: 1000, M: 1000000, B: 1000000000 };
    return units[match[2]] ? value * units[match[2]] : value;
  };

  // **Pagination Logic**
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="container mt-4">
      {error && <div className="alert alert-danger">{error}</div>}

      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead className="table-dark">
            <tr>
              <th className="poppins-regular">Product</th>
              <th className="poppins-regular">Price</th>
              <th className="poppins-regular">Monthly Sales</th>
              <th className="poppins-regular">Monthly Revenue</th>
              <th className="poppins-regular">Referral Fee</th>
              <th className="poppins-regular">Closing Fee</th>
              <th className="poppins-regular">FBA Fee</th>
              <th className="poppins-regular">Total Fees</th>
              <th className="poppins-regular">ASIN</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="9" className="text-center">
                  Loading...
                </td>
              </tr>
            ) : (
              paginatedData.map((product) => {
                // Get currency symbol dynamically
                const currencySymbol = product.currency === "USD" ? "$" : "₹";

                // Ensure product price is correctly extracted
                const productPrice =
                  parseFloat(
                    product.product_price?.toString().replace(/[^0-9.]/g, "")
                  ) || 0;
                const convertedSalesVolume = convertSalesVolume(
                  product.sales_volume
                );
                const referralFee = Math.round(productPrice * 0.08) || 0;
                const closingFee = productPrice > 500 ? 50 : 20;
                const fbaFee = product.currency === "USD" ? 2 : 80;
                const totalFees =
                  Math.round(referralFee + closingFee + fbaFee) || 0;
                const monthlyRevenue =
                  Math.round(productPrice * convertedSalesVolume) || 0;

                const productDetails = [
                  { label: currencySymbol, value: productPrice },
                  {
                    label: "",
                    value: Math.round(convertedSalesVolume * 1.126),
                  },
                  { label: currencySymbol, value: monthlyRevenue },
                  { label: currencySymbol, value: referralFee },
                  { label: currencySymbol, value: closingFee },
                  { label: currencySymbol, value: fbaFee },
                  { label: currencySymbol, value: totalFees },
                  { label: "", value: product.asin },
                ];

                return (
                  <tr
                    key={product.asin}
                    style={{ cursor: "pointer" }}
                    onClick={() => window.open(product.product_url, "_blank")}
                  >
                    <td className="poppins-regular">
                      <img
                        src={product.product_photo}
                        alt={product.product_title}
                        width="50"
                        height="50"
                        className="me-2"
                      />
                      {product.product_title}
                    </td>

                    {productDetails.map((detail, index) => (
                      <td className="poppins-regular" key={index}>
                        {detail.label} {detail.value}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
        <button
          className="btn btn-primary poppins-regular"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>

        <span className="fw-bold poppins-regular">
          Page {currentPage} of {totalPages}
        </span>

        <button
          className="btn btn-primary poppins-regular"
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Blackboxdetails;
